<template>
  <div class="py-16">
    <v-window v-model="step">
      <!-- step 1 -->
      <v-window-item :value="1">
        <v-sheet class="mb-6" color="surface">
          <v-container>
            <div class="text-h5 font-weight-bold">
              <span class="saira">Comece a competir</span>
            </div>

            <div class="text-body-1 subtext--text">Complete seu cadastro</div>
          </v-container>
        </v-sheet>

        <v-container>
          <v-card class="py-6 py-md-12 px-8 px-md-16" color="surface" flat tile>
            <div class="text-h5 font-weight-bold mb-8">
              <span class="saira"> Seus dados </span>
            </div>

            <v-form
              v-model="valid1"
              ref="form1"
              autocomplete="off"
              class="mb-6"
            >
              <div @keypress.enter.prevent="submit1()" class="mb-4">
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="pr-md-2">
                    <div class="label-input">CPF</div>
                    <v-text-field
                      v-model="form.document"
                      v-mask="'###.###.###-##'"
                      class="rounded-lg"
                      background-color="accent"
                      placeholder="Seu CPF"
                      :rules="[rules.required, rules.validDocument]"
                      solo
                      flat
                      @keydown.space.prevent
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pl-md-2">
                    <div class="label-input">Nickname</div>
                    <v-text-field
                      v-model="form.nickname"
                      class="rounded-lg"
                      background-color="accent"
                      placeholder="Seu nickname"
                      :rules="[rules.required]"
                      solo
                      flat
                      @keydown.space.prevent
                    />
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" md="6" class="pr-md-2">
                    <div class="label-input">Gênero</div>
                    <v-select
                      v-model="form.genre"
                      class="rounded-lg"
                      background-color="accent"
                      placeholder="Selecione seu gênero"
                      item-text="label"
                      item-value="value"
                      :items="genders"
                      :rules="[rules.required]"
                      solo
                      flat
                      ref="genre"
                      @focus="$refs.genre.activateMenu()"
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pl-md-2">
                    <div class="label-input">Data de nascimento</div>
                    <v-text-field
                      v-model="form.birthDate"
                      v-mask="'## / ## / ####'"
                      class="rounded-lg"
                      background-color="accent"
                      placeholder="dia / mês / ano"
                      :rules="[rules.required, rules.validDate]"
                      solo
                      flat
                      @keydown.space.prevent
                    />
                  </v-col>
                </v-row>
              </div>

              <div class="d-flex justify-end">
                <v-btn
                  class="rounded-l-0 rounded-r-lg text-none"
                  color="primary"
                  large
                  @click="submit1()"
                >
                  Próximo
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-container>
      </v-window-item>

      <!-- step 2 -->
      <v-window-item :value="2">
        <v-sheet class="mb-6" color="surface">
          <v-container>
            <div class="text-h5 font-weight-bold">
              <span class="saira">Dados escolares</span>
            </div>

            <div class="text-body-1 subtext--text">Nos informe sua escola</div>
          </v-container>
        </v-sheet>

        <v-container>
          <v-card class="py-6 py-md-12 px-8 px-md-16" color="surface" flat tile>
            <div class="d-flex align-center mb-8">
              <v-btn icon @click="step--">
                <v-icon v-text="'mdi-arrow-left'" />
              </v-btn>
              <div class="ms-4 text-h5 font-weight-bold">
                <span class="saira"> Dados da sua escola </span>
              </div>
            </div>

            <v-form
              v-model="valid2"
              ref="form2"
              autocomplete="off"
              class="mb-6"
            >
              <div @keypress.enter.prevent="submit2()" class="mb-4">
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="pr-md-2">
                    <div class="label-input">Cidade</div>
                    <v-autocomplete
                      v-model="form.schoolCity"
                      ref="schoolCity"
                      class="rounded-lg"
                      background-color="accent"
                      placeholder="Selecione sua escola"
                      item-value="city"
                      item-text="city"
                      :items="cities"
                      :rules="[rules.required]"
                      solo
                      flat
                      @focus="$refs.schoolCity.activateMenu()"
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pl-md-2">
                    <div class="label-input">Escola</div>
                    <v-autocomplete
                      v-model="form.schoolId"
                      ref="school"
                      no-data-text="Nenhuma escola encontrada, selecione a opção 'Outra' e digite sua escola"
                      class="rounded-lg"
                      background-color="accent"
                      placeholder="Selecione sua escola"
                      item-value="id"
                      item-text="name"
                      :rules="[rules.required]"
                      :disabled="!form.schoolCity"
                      :items="schools"
                      solo
                      flat
                      @focus="$refs.school.activateMenu()"
                    />
                  </v-col>
                </v-row>

                <div v-if="form.schoolId == 'other'">
                  <v-row no-gutters>
                    <v-col cols="12" md="6" class="pr-md-2">
                      <div class="label-input">Nome da escola</div>
                      <v-text-field
                        v-model="form.schoolName"
                        class="rounded-lg"
                        background-color="accent"
                        placeholder="Nome da sua escola"
                        :rules="[rules.required]"
                        solo
                        flat
                      />
                    </v-col>

                    <v-col cols="12" md="6" class="pl-md-2">
                      <div class="label-input">Bairro</div>
                      <v-text-field
                        v-model="form.schoolDistrict"
                        class="rounded-lg"
                        background-color="accent"
                        placeholder="Bairro"
                        :rules="[rules.required]"
                        solo
                        flat
                      />
                    </v-col>
                  </v-row>
                </div>

                <v-row no-gutters>
                  <v-col cols="12">
                    <div class="label-input">Ensino</div>
                    <v-select
                      v-model="form.grade"
                      class="rounded-lg"
                      background-color="accent"
                      placeholder="Categoria do ensino"
                      item-text="label"
                      item-value="value"
                      :items="gradeTypes"
                      :rules="[rules.required]"
                      solo
                      flat
                    />
                  </v-col>
                </v-row>

                <v-checkbox
                  v-model="form.terms"
                  label="Eu li e concordo com os Termos de Uso  e  Política de privacidade"
                  :rules="[rules.required]"
                />
              </div>

              <div class="d-flex justify-end">
                <v-btn
                  class="rounded-l-0 rounded-r-lg text-none"
                  color="primary"
                  large
                  @click="submit2()"
                >
                  Completar Cadastro
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-container>
      </v-window-item>

      <!-- step 3 -->
      <v-window-item :value="3">
        <v-sheet class="mb-6" color="surface">
          <v-container>
            <div class="text-h5 font-weight-bold">
              <span class="saira">Completar cadastro</span>
            </div>
          </v-container>
        </v-sheet>

        <v-container>
          <v-card class="py-6 py-md-12 px-8 px-md-16" color="surface" flat tile>
            <div class="text-h5 font-weight-bold mb-4">
              <v-icon color="green accent-2" left>
                mdi-checkbox-marked-circle-outline
              </v-icon>

              <span class="saira"> Cadastro relizado com sucesso! </span>
            </div>

            <div class="text-body-1 subtext--text mb-8">
              Agora você já possui um perfil aqui em nossa liga. Confira nossas
              modalidades de jogos e inscreva-se em alguma delas. Não perca a
              oportunidade de participar da liga mais competitiva da Paraíba.
            </div>

            <div class="d-flex">
              <v-btn
                class="rounded-l-0 rounded-r-lg text-none mr-6"
                to="/home"
                large
                text
              >
                Home
              </v-btn>

              <v-btn
                class="rounded-l-0 rounded-r-lg text-none"
                color="primary"
                large
                to="/leagues"
              >
                Conferir modalidades
              </v-btn>
            </div>
          </v-card>
        </v-container>
      </v-window-item>
    </v-window>

    <loader-hover v-if="loading" />
  </div>
</template>

<script>
import { sendUserData } from "@/services/user.js";
import { getCities, getSchools } from "@/services/schools";
import { displayAlert } from "@/utils";
import { mask } from "vue-the-mask";

const moment = require("moment");

export default {
  data() {
    return {
      step: 1,
      loading: false,
      valid1: false,
      valid2: false,
      genders: [
        {
          label: "Feminino",
          value: "F",
        },
        {
          label: "Masculino",
          value: "M",
        },
        {
          label: "Outro",
          value: "O",
        },
      ],
      gradeTypes: [
        {
          label: "Ensino Fundamental",
          value: "f",
        },
        {
          label: "Ensino Médio",
          value: "m",
        },
        {
          label: "Ensino Superior",
          value: "s",
        },
      ],
      cities: [],
      schools: [],
      form: {
        document: "",
        nickname: "",
        genre: "",
        birthDate: "",
        schoolId: "",
        schoolCity: "",
        schoolName: "",
        schoolDistrict: "",
        grade: "",
        terms: false,
      },
    };
  },

  directives: {
    mask,
  },

  beforeMount() {
    if (this.$store.getters.localUser) this.$router.go(-1);

    this.getCities();
  },

  watch: {
    [`form.schoolCity`]() {
      this.form.schoolId = null;
      this.schools = [];

      this.getSchools();
    },
  },

  computed: {
    rules() {
      return {
        required: (v) => !!v || "Campo Obrigatório",
        validDate: (v) =>
          (v.length === 14 && moment(v, "DD / MM / YYYY").isValid()) ||
          "Data Inválida",
        validDocument: (v) => v.length === 14 || "Documento Inválido",
      };
    },

    currentState() {
      return "PB";
    },
  },

  methods: {
    displayAlert,

    submit1() {
      if (!this.$refs.form1.validate()) return;

      this.step++;
    },

    async submit2() {
      if (!this.$refs.form2.validate()) return;

      try {
        this.loading = true;

        const payload = {
          document: this.form.document,
          nickname: this.form.nickname,
          genre: this.form.genre,
          birthDate: moment(this.form.birthDate, "DD / MM / YYYY").format(
            "YYYY-MM-DD"
          ),
          grade: this.form.grade,
        };

        // School
        if (this.form.schoolId !== "other") {
          payload.schoolId = this.form.schoolId;
        } else {
          payload.schoolComplementName = this.form.schoolName;
          payload.schoolComplementDistrict = this.form.schoolDistrict;
          payload.schoolComplementCity = this.form.schoolCity;
        }

        await sendUserData(payload).then(() => {
          this.$root.$emit("refresh-user");
          this.step = 3;
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async getCities() {
      try {
        this.loading = true;

        await getCities(this.currentState).then((res) => {
          this.cities = res;
        });
      } catch (err) {
        this.displayAlert("Falha ao buscar Cidades", 1);
      } finally {
        this.loading = false;
      }
    },

    async getSchools() {
      try {
        this.loading = true;

        const payload = {
          city: this.form.schoolCity,
          state: this.currentState,
        };

        await getSchools(payload).then((res) => {
          this.schools = [{ name: "Outra", id: "other" }, ...res];
        });
      } catch (err) {
        this.displayAlert("Falha ao buscar Escolas", 1);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
